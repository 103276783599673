export default () => {
  return [
    'MNU mash, version 0.0.1',
    'These shell commands are defined internally. Type \'help\' to see this list.',
    '\n',
    ' COMMAND       OPERATION',
    ' github        retrieve Github url',
    ' linkedin      retrieve LinkedIn url'
  ];
};
